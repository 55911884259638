import React from "react";

export const FormSection = ({ title, children }) => (
  <div className="content">
    <h3 className="title is-size-3 is-size-4-tablet is-size-3-desktop has-text-centered">{title}</h3>
    <div className="columns is-multiline">{children}</div>
  </div>
);

export default FormSection;
