import React from "react";
import FormField from "../FormField";

const FormInput = ({
  title,
  name,
  value,
  handleChange,
  type = "text",
  error,
  disabled = false,
  width,
  placeholder = "",
}) => {
  return (
    <FormField title={title} error={error} width={width}>
      <input
        className="input is-uppercase"
        name={name}
        type={type}
        id={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormField>
  );
};

export default FormInput;
