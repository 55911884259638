import React from "react";
import FormField from "../FormField";

const FormSelect = ({ title, name, value, handleChange, options, error }) => {
  return (
    <FormField title={title} error={error}>
      <div className="control">
        <div className="select is-fullwidth">
          <select
            name={name}
            onChange={handleChange}
            value={value}
            className="is-uppercase"
          >
            <option disabled hidden></option>
            {options.map((item) => {
              return (
                <option value={item.value} key={item.id}>
                  {item.text == null ? item.value : item.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </FormField>
  );
};

export default FormSelect;
