import React from "react";

const FormField = ({ title, error, children, width = "is-half" }) => (
  <div className={`column ${width}`}>
    <div className="field">
      <label className="label">{title}</label>
      {children}
      {error !== null ? <div className="help is-danger">{error}</div> : null}
    </div>
  </div>
);

export default FormField;
