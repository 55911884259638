import React from "react";

const SubmitButton = ({ isLoading, text }) => (
  <div className="field center columns">
    <div className="control column is-two-fifths">
      <button
        type="submit"
        className={`is-fullwidth leasingpoint-button is-primary ${
          isLoading ? "is-loading" : null
        }`}
        disabled={isLoading}
      >
        {text}
      </button>
    </div>
  </div>
);

export default SubmitButton;
